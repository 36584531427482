<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name (En)"
            rules="required"
          >
            <b-form-group
              label="Name (En)"
              label-for="name_en"
            >
              <b-form-input
                id="name_en"
                v-model="companiesForm.name_en"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name (Ar)"
            rules="required"
          >
            <b-form-group
              label="Name (Ar)"
              label-for="name_ar"
            >
              <b-form-input
                id="name_ar"
                v-model="companiesForm.name_ar"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <label class="mb-1">
            Shipping Zones
          </label>

          <div
            v-for="(zone,index) in zones"
            :key="index"
            class="d-flex mb-1"
          >

            <b-form-checkbox
              v-model="zone.is_checked"
              type="checkbox"
              :value="1"
              :unchecked-value="0"
            />
            <b-form-input
              v-model="zone.name"
              class="shippingInput"
              type="text"
              :disabled="true"
              placeholder="Zone"
            />
            <b-form-input
              v-model="zone.price"
              class="shippingInputPrice"
              type="text"
              :disabled="!zone.is_checked"
              placeholder="price"
            />
          </div>
        </b-col>
        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addCompany()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addCompany()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>

      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import handleFormData from '@/global-compositions/handleFormData'

// eslint-disable-next-line no-unused-vars

export default {
  components: {
  },
  data() {
    return {
      required,
      id: this.$store.state.generalIds.id,
      selected: [],
      allSelected: [],
      indeterminate: false,
      allPermissions: [],
      users: [],
      zones: [],
      zonesId: [],
      Company: [],
      countryId: '',
      loader: false,
      validationErrors: {},
      errors: {},
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const companiesForm = ref({ })
    // eslint-disable-next-line camelcase
    const { formData, setFormData } = handleFormData()

    return {
      getValidationState,
      companiesForm,
      formData,
      setFormData,
    }
  },
  mounted() {
    this.showcompanies()
    this.getZones()
  },
  methods: {
    // eslint-disable-next-line consistent-return
    toggleAll(value, key) {
      if (this.allSelected[key]) {
        // eslint-disable-next-line array-callback-return
        value.items.map(item => {
          this.companiesForm.permission.push(item.id)
        })
      } else {
        // eslint-disable-next-line array-callback-return
        value.items.map(item => {
          this.companiesForm.permission.splice(this.companiesForm.permission.indexOf(item.id), 1)
        })
      }
    },
    async getZones() {
      if (this.$route.params.id) {
        await axios.get(`admin/zones_list?company_id=${this.$route.params.id}`).then(res => {
          if (res.status === 200) {
            this.zones = res.data?.data.zones
          }
        })
      } else {
        await axios.get('admin/zones_list').then(res => {
          if (res.status === 200) {
            this.zones = res.data?.data.zones
          }
        })
      }
      return true
    },
    showcompanies() {
      if (this.$route.params.id) {
        axios.get(`admin/companies/${this.$route.params.id}`).then(res => {
          this.companiesForm = res.data.data.company
        })
      } else {
        return false
      }
      return true
    },

    addCompany() {
      if (this.$route.params.id) {
        this.loader = true

        this.formData.append('name_ar', this.companiesForm.name_ar)
        this.formData.append('name_en', this.companiesForm.name_en)

        this.zones.forEach((zone, index) => {
          if (zone.is_checked) {
            this.formData.append(`zones[${index}][ids]`, zone.id)
            this.formData.append(`zones[${index}][price]`, zone.price)
          }
        })
        axios.post(`admin/companies/${this.$route.params.id}`, this.formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'shipping-companies' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.setFormData(this.companiesForm)

        this.zones.forEach((zone, index) => {
          if (zone.is_checked) {
            this.formData.append(`zones[${index}][ids]`, zone.id)
            this.formData.append(`zones[${index}][price]`, zone.price)
          }
        })
        this.loader = true
        axios.post('admin/companies', this.formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'shipping-companies' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Somthing Went Wrong',
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },

  },

}
</script>

  <style lang="scss" scoped>
  .shippingInput {
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.shippingInputPrice {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
  </style>
